export const menus = [
  {
    label: "Home",
    link: "/",
  },
  {
    label: "About Us",
    link: "/about-us/",
  },
  {
    label: "Services",
    link: "/services/",
  },
  // {
  //   label: "Projects",
  //   link: "/projects/",
  // },
  // {
  //   label: "Shop",
  //   link: "/shop/",
  // },
  // {
  //   label: "Blog",
  //   link: "/blog/",
  // },
  {
    label: "Contact",
    link: "/contact/",
  },
];
