"use client";

import React from "react";
import Link from "next/link";

const TopHeader: React.FC = () => {
  return (
    <>
      <div className='topbar-wrap-area'>
        <div className='container-fluid'>
          <div className='row align-items-center justify-content-center'>
            <div className='col-lg-3 col-md-3'>
              <div className='topbar-content'>
                <span>
                  환영합니다. 도움이 필요하신가요?{" "}
                  <Link href='/contact'>Contact Us</Link>
                </span>
              </div>
            </div>
            <div className='col-lg-9 col-md-9'>
              <ul className='topbar-action-list'>
                <li>
                  <i className='fa-solid fa-location-pin'></i> 성북구
                  동소문로63, 드림트리빌딩 6층
                </li>
                <li>
                  <i className='fa-solid fa-phone'></i>{" "}
                  <a href='tel:821084901181'>+82 10-8490-1181</a>
                </li>
                <li>
                  <i className='fa-solid fa-envelope'></i>{" "}
                  <a href='mailto:dendelion@dlion.kr'>dendelion@dandelion.kr</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopHeader;
