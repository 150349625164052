"use client";

import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import Link from "next/link";
import Image from "next/image";

const DomainSearch: React.FC = () => {
  const [toggler, setToggler] = useState(false);
  return (
    <>
      <FsLightbox
        toggler={toggler}
        sources={[""]}
      />

      <div className='ai-main-banner-area'>
        <div className='container-fluid'>
          <div className='ai-main-banner-content'>
            <h1
              data-aos='fade-up'
              data-aos-duration='1000'
              data-aos-delay='100'
            >
              교육부터 취업까지 한번에!
              <br /> <span>재활 전문가</span>를 위한 <br />
              국내 유일 통합형 플랫폼
            </h1>

            <p
              data-aos='fade-up'
              data-aos-duration='1000'
              data-aos-delay='200'
            >
              재활 치료와 관련 분야에 관심 있는 분들을 위한 공간에서 교육과
              다양한 기회를 만나보세요.
            </p>

            <ul
              className='banner-btn'
              data-aos='fade-up'
              data-aos-duration='1000'
              data-aos-delay='300'
            >
              {/* <li>
                <Link
                  href='/programs'
                  className='btn btn-primary'
                >
                  솔루션 보기
                </Link>
              </li> */}
              {/* <li>
                <div
                  onClick={() => setToggler(!toggler)}
                  className='video-btn'
                >
                  <i className='flaticon-play-button'></i>
                  우리의 이야기 보기
                </div>
              </li> */}
            </ul>
          </div>
        </div>

        <div className='ai-main-banner-image'>
          <Image
            src='/images/home-six/banner/main1.png'
            alt='image'
            width={678}
            height={450}
          />
          <div className='circle-shape'></div>
        </div>
        <div className='ai-main-banner-image right-image'>
          <Image
            src='/images/home-six/banner/main2.png'
            alt='image'
            width={645}
            height={450}
          />
          <div className='circle-shape'></div>
        </div>

        {/* Shape Images */}
        <div className='ai-main-banner-shape-1'>
          <Image
            src='/images/home-six/banner/shape-1.png'
            alt='image'
            width={108}
            height={100}
          />
        </div>
        <div className='ai-main-banner-shape-2'>
          <Image
            src='/images/home-six/banner/shape-2.png'
            alt='image'
            width={46}
            height={46}
          />
        </div>
        <div className='ai-main-banner-shape-3'>
          <Image
            src='/images/home-six/banner/shape-2.png'
            alt='image'
            width={46}
            height={46}
          />
        </div>
      </div>
    </>
  );
};

export default DomainSearch;
